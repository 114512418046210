@use "sass:map";
@use "sass:math";

$black:#333333;
$red:#E82228;
$gold:#EFE3C5;
$font-din: 'Oswald', sans-serif;
@function px2vw($size, $std_width: 375) {
   $no_unit_size: calc($size / ($size * 0 + 1));
   @return calc(100 / $std_width) * $no_unit_size * 1vw;
 }
 // SPデザインPC変換
@function dpx($size, $dpx_width: 375px, $dpx_resize: 533px) {
   $dpx_calc: calc($dpx_width / $dpx_resize);
   @return math.round(calc($size / $dpx_calc) * 1px);
}
// break point
// デフォルトはmdでレイアウト切り替え
$breakpoints: (
  'sm': (max-width: 767px),
  'md': (min-width: 768px),
  'lg': (min-width: 980px),
  'xl': (min-width: 1200px),
  'xxl': (min-width: 1300px),
  'pd': (max-width: 961px),
  'sm_landscape' : '(max-width: 812px) and (max-height: 576px) and (orientation: landscape)',
  'sm_tall' : '(max-width: 539px) and (min-height: 700px) and (max-height: 1024px) and (orientation: portrait)' ,
  'md_portrait' : '(min-width: 540px) and (max-width: 768px) and (max-height: 1024px) and (orientation: portrait)',
  'ie11' :  '(min-width: 768px) and (-ms-high-contrast: none)'
) !default;

/* --------------------------------------------------------------------------
  media query (SP（小）→ PC（大）の順に適用)
  -------------------------------------------------------------------------- */
@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map.get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  @else {
    @error "指定されたブレークポイントは定義されていません。" + "指定できるブレークポイントは次のとおりです。 -> #{map-keys($breakpoints)}";
  }
}
@mixin opacity(){
   transition:all 0.3s;
   &:hover{
      opacity:0.6;
   }
}
@mixin iconSet(){
   display: block;
   content:"";
   position:absolute;
   background-size: contain;
   background-repeat: no-repeat;
}

img{
   width:100%;
}
body{
  font-family:"Noto Sans JP", sans-serif;
}
.pc{
   display:block;
   @include mq(sm){
   		display:none;
   }
}
.sp{
   display:none;
      @include mq(sm){
   		display:block;
   }
}

/*==============================================================
# fadeIn
==============================================================*/
.fadeIn_main {
  opacity: 0;
}
.fadeIn_main.is-show {
  animation-name: mainFade;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 1s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
.fadeIn_bg {
  opacity: 0;
  transition: 1s;
}
.fadeIn_bg.is-show {
  animation-name: mainFade;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 2s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
.fadeIn_normal {
  opacity: 0;
}
.fadeIn_normal.is-show {
  animation-name: mainFade;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
@keyframes mainFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn_up {
  opacity: 0;
  transform: translate(0, 50px);
  transition: 1s;
}
.fadeIn_up.is-show {
  transform: translate(0, 0);
  opacity: 1;
}
.fadeIn_up_repeat {
  opacity: 0;
  transform: translate(0, 50px);
  transition: 1s;
}
.fadeIn_up_repeat.is-show {
  transform: translate(0, 0);
  opacity: 1;
}

.fadeIn_left{
  opacity: 0;
  transform: translateX(30px);
  transition-duration: 1.5s;
  transition-delay:3s;
  @include mq(pd){
    transition-delay:1.5s;
  }
}
.fadeIn_left.is-show {
  transform: translateX(0%);
  opacity: 1;
}
.fadeIn_left2{
  opacity: 0;
  transform: translateX(30px);
  transition-duration: 1.5s;
  transition-delay:0.5s;
}
.fadeIn_left2.is-show {
  transform: translateX(0%);
  opacity: 1;
}
.fadeIn_right{
  opacity: 0;
  transform: translateX(-30px);
  transition-duration: 1.5s;
  transition-delay:3s;
  @include mq(pd){
    transition-delay:1.5s;
  }
}
.fadeIn_right.is-show {
  transform: translateX(0%);
  opacity: 1;
}
.fadeIn_right2{
  opacity: 0;
  transform: translateX(-30px);
  transition-duration: 1.5s;
  transition-delay:0.5s;
}
.fadeIn_right2.is-show {
  transform: translateX(0%);
  opacity: 1;
}