@use "../foundation/base" as *;

.wrap{
    position:relative;
    width:100%;
    height:100svh;
    background-image: url(../img/pc-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index:1;
    &:after{
        content:"";
        display:block;
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100svh;
        background-image: url(../img/pc-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index:1;
    }
}
.wrap-wave02{
    position:fixed;
    overflow:hidden;
    top:-15vw;
    left:0px;
    width:100%;
    z-index:2;
    @include mq(pd){
        display:none;
    }
}
.wrap-wave03{
    position:fixed;
    overflow:hidden;
    width:100%;
    top:-25vw;
    left:0;
    z-index:3;
    transform: scale(-1, 1);
    @include mq(pd){
        display:none;
    }
}
.bgcontent{
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100svh;
    z-index:3;
    @include mq(pd){
        display:none;
    }
}
.swiper-wrapper{
    transition-timing-function: linear;
}
.bgcontent-img01{
    position:absolute;
    left:0;
    right:0;
    bottom:-3svh;
    margin:auto;
    width:72svh;
    height:110svh;
    transform:translateX(calc(-220px - (36svh)));
    img{
        height:100%;
        object-fit:contain;
    }
}
.bgcontent-img02{
    position:absolute;
    left:0;
    right:0;
    bottom:-3svh;
    margin:auto;
    width:72svh;
    height:110svh;
    transform:translateX(calc(220px + (36svh)));
    img{
        height:100%;
        object-fit:contain;
    }
}
.bgcontent__text01{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
    width:fit-content;
    height:23px;
    font-size:23px;
    font-weight: 900;
    letter-spacing: 0.2em;
    transform:translateX(calc(-235px - 50%));
}
.bgcontent__text02{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
    width:fit-content;
    height:23px;
    font-size:23px;
    font-weight: 900;
    letter-spacing: 0.2em;
    transform:translateX(calc(235px + 50%));
}
.maincontent{
    position:absolute;
    left:0;
    right:0;
    top:0;
    margin:auto;
    width:375px;
    -webkit-font-smoothing: antialiased;
    z-index:3;
    background-image: url(../img/sp-bg.jpg);
    @include mq(pd){
        width:100%;
        overflow:hidden;
    }
    &:before{
        content:"";
        position:absolute;
        top:0;
        left:-15px;
        width:2px;
        height:100%;
        background-color:white;
        @include mq(pd){
            display:none
        }
    }
    &:after{
        content:"";
        position:absolute;
        top:0;
        right:-15px;
        width:2px;
        height:100%;
        background-color:white;
        @include mq(pd){
            display:none;
        }
    }
}
.header{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:108px;
    padding:21px 19px;
    @include mq(pd){
        height:px2vw(108);
        padding:px2vw(21) px2vw(20);
    }
}
.header__logo{
    width:234px;
    @include mq(pd){
        width:px2vw(234);
    }
}
.mv{
    position:relative;
    margin-top: 10px;
    overflow:hidden visible;
    @include mq(pd){
        margin-top: px2vw(10);
    }
}
.mv__img{
    width:100%;
}
.mv__cloud01{
    position:absolute;
    top:-5px;
    right:-36px;
    width:150px;
    height:72px;
    @include mq(pd){
        top:px2vw(-5);
        right:px2vw(-36);
        width:px2vw(150);
        height:px2vw(72);
    }
}
.mv__cloud02{
    position:absolute;
    top:100px;
    left:0;
    width:158px;
    height:93px;
    @include mq(pd){
        top:px2vw(100);
        width:px2vw(158);
        height:px2vw(93);
    }
}
.mv-bandwrap{
    position:relative;
    margin-top:-65px;
    margin-left: -3px;
    width:102%;
    transform: rotate(-5deg);
    overflow:hidden;
    z-index:2;
    @include mq(pd){
        margin-top: px2vw(-65);
        margin-left: px2vw(-3);
    }
}
.mv-band{
    display:flex;
    align-items:center;
    width:105%;
    height:52px;
    padding-left: 70px;
    background-image: url(../img/band-bg.jpg);
    overflow:hidden;
    white-space:nowrap;
    @include mq(pd){
        height:px2vw(52);
        padding-left: px2vw(70);
    }
}
.mv-band__list{
    display:flex;
    width:100%;
    animation: flowing 20s -10s linear infinite;
}
.mv-band__list02{
    display:flex;
    width:100%;
    animation: flowing2 20s linear infinite;
}
.mv-band__text{
    flex-shrink:0;
    width:268px;
    margin-right: 32px;
    @include mq(pd){
        width:px2vw(268);
        margin-right: px2vw(32);
    }
}
@keyframes flowing {
    0% { transform:translateX(100%); }
    100% { transform:translateX(-110%); }
}
@keyframes flowing2 {
    0% { transform:translateX(0%); }
    100% { transform:translateX(-210%); }
}
.experience{
    position:relative;
    overflow:hidden;
    width:100%;
    margin-top: -17px;
    z-index:1;
    @include mq(pd){
        margin-top: px2vw(-17);
    }
}
.experience__img01{
    position:relative;
    width:100%;
    z-index:2;
}
.experience__content{
    position:relative;
    padding: 60px 0px;
    z-index:1;
    @include mq(pd){
        padding:px2vw(60) 0;
    }
}
.experience__img02{
    position:relative;
    width:100%;
    z-index:2;
}
.experience__cloud01{
    position:absolute;
    top:-35px;
    right:15px;
    width:149px;
    height:88px;
    z-index:1;
    @include mq(pd){
        top:px2vw(-35);
        right:px2vw(15);
        width:px2vw(149);
        height:px2vw(88);
    }
}
.experience__cloud02{
    position:absolute;
    top:460px;
    left:-75px;
    width:276px;
    height:121px;
    z-index:1;
    @include mq(pd){
        top:px2vw(460);
        left:px2vw(-75);
        width:px2vw(276);
        height:px2vw(121);
    }
}
.experience__cloud03{
    position:absolute;
    top:610px;
    right:-55px;
    width:425px;
    height:213px;
    z-index:1;
    @include mq(pd){
        top:px2vw(610);
        right:px2vw(-50);
        width:px2vw(400);
        height:px2vw(213);
    }
}
.open{
    position:relative;
    width:100%;
    margin-top: 10px;
    @include mq(pd){
        margin-top: px2vw(10);
    }
    &:before{
        @include iconSet();
        background-image: url(../img/open-top.png);
        top:-10px;
        right:0;
        left:0;
        margin:auto;
        width:334px;
        height:161px;
        z-index:2;
        @include mq(pd){
            top:px2vw(-10);
            width:px2vw(334);
            height:px2vw(161);
        }
    }
}
.open__heading{
    position:absolute;
    top:30px;
    left:0;
    width:320px;
    z-index:3;
    @include mq(pd){
        top:px2vw(30);
        width:px2vw(320);
    }
}
.open__inner{
    position:relative;
    width:321px;
    margin:auto;
    padding-top: 130px;
    padding-bottom: 50px;
    border-radius:5px;
    border:2px solid $gold;
    background-color: white;
    @include mq(pd){
        width:px2vw(321);
        padding-top: px2vw(130);
        padding-bottom: px2vw(50);
        border-radius:px2vw(5);
        border:px2vw(2) solid $gold;
        }
    &:before{
        content:"";
        position:absolute;
        top:39px;
        left:-8.5px;
        display:inline-block;
        width:2px;
        height:85%;
        background-color: #E1C98C;
        @include mq(pd){
            top:px2vw(39);
            left:px2vw(-8.5);
            width:px2vw(2);
        }
    }
    &:after{
        content:"";
        position:absolute;
        top:39px;
        right:-8.5px;
        display:inline-block;
        width:2px;
        height:85%;
        background-color: #E1C98C;
        @include mq(pd){
            top:px2vw(39);
            right:px2vw(-8.5);
            width:px2vw(2);
        }
    }
    &.active{
        &:before{
            height:90%;
        }
        &:after{
            height:90%;
        }
    }
}
.open__img01{
    width:290px;
    margin:auto;
    @include mq(pd){
        width:px2vw(291);
    }
}
.open__targetarea{
    display:grid;
    grid-template-columns: 135px 135px;
    column-gap:8px;
    row-gap:8px;
    width:fit-content;
    margin:auto;
    margin-top: 13px;
    @include mq(pd){
        grid-template-columns: px2vw(135) px2vw(135);
        column-gap:px2vw(8);
        row-gap:px2vw(8);
        margin-top: px2vw(13);
    }
}
.open__target{
    padding:6px 10px;
    border-radius:4px;
    text-align:center;
    font-size:16px;
    font-weight: 900;
    color:white;
    background-color: $red;
    @include mq(pd){
        padding:px2vw(6) px2vw(10);
        border-radius:px2vw(4);
        font-size:px2vw(16);
    }
}
.open__img02{
    width:274px;
    margin:auto;
    margin-top: 25px;
    @include mq(pd){
        width:px2vw(274);
        margin-top: px2vw(25);
    }
}
.open-info{
    position:relative;
    width:279px;
    margin:auto;
    margin-top: 22px;
    border-top:1px solid #E1C98C;
    border-bottom:1px solid #E1C98C;
    @include mq(pd){
        width:px2vw(279);
        margin-top: px2vw(22);
    }
    &:after{
        @include iconSet();
        background-image: url(../img/open-bottom.png);
        bottom:-65px;
        left:-27.5px;
        width:334px;
        height:161px;
        z-index:2;
        @include mq(pd){
            bottom:px2vw(-65);
            left:px2vw(-27.5);
            width:px2vw(334);
            height:px2vw(161);
        }
    }
}
.open-info__btn{
    display:block;
    width:100%;
    position:relative;
    padding:24px 0px;
    text-align:center;
    font-size:16px;
    font-weight: 900;
    z-index:3;
    @include mq(pd){
        padding:px2vw(24) 0;
        font-size:px2vw(16);
    }
    span{
        position:relative;
        display:block;
        width:fit-content;
        margin:auto;
        &:after{
            @include iconSet();
            background-image: url(../img/open-info-icon.png);
            top:0px;
            right:-30px;
            width:20px;
            height:20px;
            transition:all 0.3s;
            @include mq(pd){
                right:px2vw(-30);
                width:px2vw(20);
                height:px2vw(20);
            }
        }
    }
    &.active{
        span{
            &:after{
                transform:rotate(45deg);
            }
        }
    }
}
.open-info__area{
    display:none;
    padding-bottom: 20px;
    @include mq(pd){
        padding-bottom: px2vw(20);
    }
}
.open-info-detail__box{
    display:grid;
    grid-template-columns: 72px 1fr;
    column-gap:10px;
    margin-top: 12px;
    @include mq(pd){
        grid-template-columns: px2vw(72) 1fr;
        column-gap:px2vw(10);
        margin-top: px2vw(12);
    }
    dt{
        display:grid;
        place-items:center;
        justify-content:center;
        padding:8px 0px;
        font-size:14px;
        font-weight: 700;
        line-height: 1.3;
        background-color: $gold;
        @include mq(pd){
            padding:px2vw(8) 0;
            font-size:px2vw(14);
        }
    }
    dd{
        display:grid;
        align-items:center;
        font-size:13px;
        font-weight: 500;
        line-height: 1.3;
        @include mq(pd){
            font-size:px2vw(13);
        }
    }
}
.open-info-scheduleheading{
    position:relative;
    padding-left: 23px;
    margin-top: 40px;
    font-size:16px;
    font-weight: 700;
    @include mq(pd){
        padding-left: px2vw(23);
        margin-top: px2vw(40);
        font-size:px2vw(16);
    }
    &:before{
        @include iconSet();
        background-image: url(../img/open-schedule-icon.png);
        left:0;
        top:0;
        width:20px;
        height:20px;
        @include mq(pd){
            width:px2vw(20);
            height:px2vw(20);
        }
    }
}
.open-info-schedule{
    display:grid;
    grid-template-columns: 38px 1fr;
    column-gap:18px;
    margin-top: 20px;
    @include mq(pd){
        grid-template-columns: px2vw(38) 1fr;
        column-gap:px2vw(18);
        margin-top: px2vw(20);
    }
}
.open-info-schedule__time{
    font-size:14px;
    font-weight: 700;
    line-height: 1.3;
    @include mq(pd){
        font-size:px2vw(14);
    }
}
.open-info-schedule__actiontext01{
    margin-top: 20px;
    padding-bottom: 4px;
    border-bottom:1px solid rgba(0, 0, 0, 0.10);
    font-size:14px;
    font-weight: 700;
    line-height: 1.3;
    @include mq(pd){
        margin-top: px2vw(20);
        padding-bottom: px2vw(4);
        font-size:px2vw(14);
    }
    &:first-child{
        margin-top: 0;
    }
}
.open-info-schedule__actiontext02{
    margin-top: 5px;
    font-size:13px;
    font-weight: 500;
    line-height: 1.3;
    @include mq(pd){
        margin-top: px2vw(5);
        font-size:px2vw(13);
    }
}
.contact{
    margin-top: 30px;
    padding-bottom: 70px;
    @include mq(pd){
        margin-top: px2vw(30);
        padding-bottom: px2vw(70);
    }
}
.contact__text{
    width:321px;
    margin:auto;
    font-size:14px;
    font-weight: 700;
    line-height: 2.2;
    color:white;
    @include mq(pd){
        width:px2vw(321);
        font-size:px2vw(14);
    }
}
.contact__btn{
    display:flex;
    justify-content:center;
    align-items:center;
    width:270px;
    height:60px;
    margin:auto;
    margin-top: 50px;
    border-radius:8px;
    border:2px solid #E1C98C;
    font-size:18px;
    font-weight: 900;
    background-color: #E1C98C;
    transition:all 0.3s;
    @include mq(pd){
        width:px2vw(270);
        height:px2vw(60);
        margin-top: px2vw(50);
        border-radius:px2vw(8);
        border:px2vw(2) solid #E1C98C;
        font-size:px2vw(18);
    }
    &:hover{
        background-color: white;
    }
}
.contact__caution{
    margin-top: 10px;
    text-align:center;
    font-size:12px;
    font-weight: 700;
    color:white;
    @include mq(pd){
        margin-top: px2vw(10);
        font-size:px2vw(12);
    }
}
.contact__heading{
    margin-top: 30px;
    padding:8px 0px;
    text-align:center;
    font-size:18px;
    font-weight: 900;
    border-top:1px solid white;
    border-bottom:1px solid white;
    color:white;
    @include mq(pd){
        margin-top: px2vw(30);
        padding:px2vw(8) 0;
        font-size:px2vw(18);
    }
}
.contact-container{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top: 12px;
    @include mq(pd){
        margin-top: px2vw(12);
    }
}
.contact__tel{
    display:block;
    position:relative;
    padding-left: 25px;
    text-decoration:underline;
    font-size:14px;
    font-weight: 700;
    color:white;
    @include mq(pd){
        padding-left: px2vw(25);
        font-size:px2vw(14);
    }
    &:before{
        @include iconSet();
        background-image: url(../img/contact-tel-icon.png);
        top:-1px;
        left:0px;
        width:20px;
        height:20px;
        @include mq(pd){
            width:px2vw(20);
            height:px2vw(20);
        }
    }
    &:hover{
        text-decoration: none;
    }
}
.contact__time{
    margin-top: 7px;
    font-size:12px;
    font-weight: 700;
    line-height: 1;
    color:white;
    @include mq(pd){
        margin-top: px2vw(7);
        font-size:px2vw(12);
    }
}
.contact__email{
    display:block;
    position:relative;
    margin-top: 10px;
    padding-left: 25px;
    text-decoration:underline;
    font-size:14px;
    font-weight: 700;
    color:white;
    @include mq(pd){
        margin-top: px2vw(10);
        padding-left: px2vw(25);
        font-size:px2vw(14);
    }
    &:before{
        @include iconSet();
        background-image: url(../img/contact-email-icon.png);
        top:-1px;
        left:0px;
        width:20px;
        height:20px;
        @include mq(pd){
            width:px2vw(20);
            height:px2vw(20);
        }
    }
    &:hover{
        text-decoration: none;
    }
}
.about__img01{
    width:308px;
    @include mq(pd){
        width:px2vw(308);
        margin-top: px2vw(20);
    }
}
.about__img02{
    width:308px;
    margin-top: 30px;
    margin-left: auto;
    @include mq(pd){
        width:px2vw(308);
        margin-top: px2vw(30);
    }
}
.about__img03{
    width:308px;
    margin-top: 30px;
    @include mq(pd){
        width:px2vw(308);
        margin-top: px2vw(30);
    }
}
.about__text{
    width:321px;
    margin:auto;
    margin-top: 60px;
    font-size:14px;
    font-weight: 700;
    line-height: 2.2;
    color:white;
    @include mq(pd){
        width:px2vw(321);
        margin-top: px2vw(60);
        font-size:px2vw(14);
    }
}
.about__heading{
    width:321px;
    margin:auto;
    margin-top: 30px;
    font-size:24px;
    font-weight: 700;
    letter-spacing: 0.1em;
    color:white;
    @include mq(pd){
        width:px2vw(321);
        margin-top: px2vw(30);
        font-size:px2vw(24);
    }
}
.about-box{
    width:335px;
    margin:auto;
    margin-top: 45px;
    @include mq(pd){
        width:px2vw(335);
        margin-top: px2vw(45);
    }
}
.footer{
    position:relative;
    width:100%;
    height:399px;
    margin-top: 80px;
    background-image: url(../img/footer-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    @include mq(pd){
        height:px2vw(399);
        margin-top: px2vw(80);
    }
}
.footer__container{
    position:absolute;
    right:0;
    left:0;
    bottom:50px;
    margin:auto;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0px 25px;
    @include mq(pd){
        bottom:px2vw(50);
        padding:0 px2vw(25);
    }
}
.footer-iconarea{
    display:grid;
    grid-template-columns: 32px 32px;
    column-gap:10px;
    @include mq(pd){
        grid-template-columns: px2vw(32) px2vw(32);
        column-gap:px2vw(10);
    }
}
.footer-iconarea__icon{
    display:block;
    @include opacity();
}
.footer__link{
    font-size:14px;
    font-weight: 700;
    text-decoration:underline;
    @include opacity();
    @include mq(pd){
        font-size:px2vw(14);
    }
}